<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <btn-new to="/users/new" />
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="users"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50]
              }"
              @update:options="loadUsers"
            >
              <template v-slot:[`item.active`]="{ item }">
                {{ item.active ? 'Sim' : 'Não' }}
              </template>
              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <table-btn-edit :href="`/users/${item.id}/edit`" />
                <table-btn-delete @click="clickDeleteItem(item)" />
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <dialog-delete
      :show="dialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
      @cancel="dialogDelete = false"
    />
  </div>
</template>

<script>
  import usersApi from '@/api/users'

  export default {
    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        dialogDelete: false,
        count: 0,
        deleteId: null,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Nome', value: 'name' },
          { text: 'Usuário', value: 'username' },
          { text: 'Cliente', value: 'customer.companyName' },
          { text: 'Ativo', value: 'active' },
          { text: '', value: 'actions', align: 'right' },
        ],
        users: [],
        pagination: {},
      }
    },

    methods: {
      async loadUsers () {
        try {
          this.loading = true

          const response = await usersApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.users = response.data.users
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async clickDeleteItem (user) {
        this.deleteId = user.id
        this.dialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await usersApi.delete(this.deleteId)

          this.loadUsers()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.dialogDelete = false
        }
      },

    },

  }
</script>
